import {Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "hamburger" ];

  makeContainerListen(containerElement, main, sidebarElement){
    containerElement.addEventListener('click', () => {
      main.classList.remove('main--sidebar-open');
      sidebarElement.classList.remove('o-sidebar--active');
      this.hamburgerTarget.classList.remove('a-hamburger--active');
    });
  }

  openNav() {
    const sidebarElement = document.querySelector('.o-sidebar');
    const sidebarIsActive = sidebarElement.classList.contains('o-sidebar--active')
    const main = document.querySelector('main');
    const mainIsActive = main.classList.contains('main--sidebar-active');
    const containerElement = document.querySelector('.o-container');
    this.hamburgerTarget.classList.toggle('a-hamburger--active');
    sidebarElement.classList.toggle('o-sidebar--active');
    main.classList.toggle('main--sidebar-open');

    if(mainIsActive === false && sidebarIsActive === false) {
      this.makeContainerListen(containerElement, main, sidebarElement);
    }
  }

}