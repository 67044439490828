import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [ "alert" ]

  initialize() {
    setTimeout(() => {
      this.closeAlert()
    }, 3500);
  }

  closeAlert() {
    this.alertTarget.classList.add('anim-disappear');
    setTimeout(() => {
      this.alertTarget.style.display = "none";
    }, 4000);
  }


}