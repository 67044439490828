import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["icon"];

  connect() {
    this._toggleFavorited();
  }

  onSuccess(event) {
    const data = JSON.parse(event.detail[0]);
    this.isFavorited = data.favorited;
    this._toggleFavorited();
  }

  _toggleFavorited() {
    this.iconTarget.classList.toggle("ion-md-eye", this.isFavorited);
    this.iconTarget.classList.toggle("ion-md-eye-off", !this.isFavorited);
    this.element.setAttribute(
      "data-method",
      this.isFavorited ? "delete" : "post"
    );
  }

  get isFavorited() {
    return this.data.get("favorited") === "true";
  }

  set isFavorited(value) {
    this.data.set("favorited", value);
  }
}
