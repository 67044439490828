import { saveAs } from "file-saver";
import consumer from "./consumer";

consumer.subscriptions.create(
  {
    channel: "DownloadsChannel"
  },
  {
    received(data) {
      try {
        const blob = new Blob([data.csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, data.filename);
      } catch (e) {
        console.error("File download error:", e);
      }
    }
  }
);
