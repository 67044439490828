document.addEventListener('turbolinks:load', function(){

  const toTop = document.querySelector('.m-back-to-top');
  const searchBar = document.querySelector('.o-searchbar');

  if(toTop) {

    function scrollToTop() {
      document.body.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    document.querySelector('.m-back-to-top').addEventListener('click', function() {
      scrollToTop();
    });

    observer = new IntersectionObserver( (entries) => {

      // HEADER IS SHOWING - THIS IS A TRUE FALSE VALUE REPRESENTING WEATHER OR NOT THE HEADER IS IN THE VIEWPORT
      let headerIsShowing = entries[0].isIntersecting;

      // THIS TOGGLES A CLASS ON THE BACK TO TOP BUTTON
      if (!headerIsShowing) {
        toTop.classList.add('is-showing');
      } else {
        toTop.classList.remove('is-showing');
      }

    })

    // THIS OBESERVES THE SEARCHBAR
    observer.observe(searchBar);

  }

});