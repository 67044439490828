require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("local-time").start();
require("channels");
require("chartkick/chart.js")

require("../styles/application.scss");
require.context("../images", true);

import "controllers";
import "scripts";
