import Choices from "choices.js";

const choiceOptions = {
  // placeholder: true,
  // placeholderValue: 'Please Select',
  // itemSelectText: 'itemsSelectText',
  searchEnabled: true,
  shouldSort: false,

  classNames: {
    containerOuter: "m-choices",
    containerInner: "m-choices__inner",
    input: "m-choices__input",
    inputCloned: "m-choices__input--cloned",
    list: "m-choices__list",
    listItems: "m-choices__list--multiple",
    listSingle: "m-choices__list--single",
    listDropdown: "m-choices__list--dropdown",
    item: "m-choices__item",
    itemSelectable: "m-choices__item--selectable",
    itemDisabled: "m-choices__item--disabled",
    itemChoice: "m-choices__item--choice",
    placeholder: "m-choices__placeholder",
    group: "m-choices__group",
    groupHeading: "m-choices__heading",
    button: "m-choices__button",
    activeState: "is-active",
    focusState: "is-focused",
    openState: "is-open",
    disabledState: "is-disabled",
    highlightedState: "is-highlighted",
    flippedState: "is-flipped",
    loadingState: "is-loading",
    noResults: "has-no-results",
    noChoices: "has-no-choices"
  }
};

function stylizeSelectFields() {
  Array.from(
    document.querySelectorAll("select:not([data-select-enabled])")
  ).map(select => {
    new Choices(select, choiceOptions);
    select.setAttribute("data-select-enabled", true);
    // find the data-attribute textSearch
    if(select.hasAttribute("data-searchable")) {
      select.closest('.m-choices').classList.add('m-choices__searchable');
    };
  });
}

document.addEventListener("turbolinks:load", () => {
  stylizeSelectFields();

  const observer = new MutationObserver(stylizeSelectFields);

  window.selectFieldObserver$ = observer;

  observer.observe(document.body, {
    attributes: false,
    childList: true,
    subtree: true
  });
});

document.addEventListener("turbolinks:before-cache", () => {
  if (window.selectFieldObserver$) {
    window.selectFieldObserver$.disconnect();
  }
});
